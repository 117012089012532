jQuery(document).ready(function($) {
	if ($(".widget_search").length) init_autosuggest();

	setContrast();

	$(".a-read-on").click(function() {
		document.getElementsByTagName("BODY")[0].appendChild(document.createElement("script")).src =
			getProtocol() + "://babm.texthelp.com/Bookmarklet.ashx?l=nl";
	});

	$(".switch-contrast").on("click", switch_stylesheet);
});

function getProtocol() {
	return window.location.href.indexOf("https") == 0 ? "https" : "http";
}

var autosuggest_pos = -1;
var autosuggest_max = 9;

function init_autosuggest() {
	// align suggestions
	// $('#suggestions').css('left',$('#search').position().left);

	jQuery("#s")
		.hover(
			function() {
				// if (jQuery(this).val()=='...') jQuery(this).val(jQuery(this).attr('rel'));
			},
			function() {
				// if (jQuery(this).val()==jQuery(this).attr('rel')) jQuery(this).val('...');
			}
		)
		.click(function() {
			jQuery(this).val("");
		})
		.keyup(function(event) {
			// normal keypress
			if (
				event.which != 39 &&
				event.which != 37 &&
				event.which != 38 &&
				event.which != 40 &&
				event.which != 13 &&
				event.which != 9
			) {
				autosuggest_pos = -1;

				var val = jQuery(this).val();
				if (val.length > 0) {
					// get suggestions
					jQuery.get("?a=get-suggestions&q=" + encodeURIComponent(val), function(data) {
						jQuery("#suggestions").html(data);

						if (data) {
							jQuery("#suggestions").fadeIn("fast");

							// highlight by mouse
							jQuery("#suggestions li")
								.hover(
									function() {
										jQuery("#suggestions li").removeClass("selected");
										jQuery(this).addClass("selected");
									},
									function() {
										// nothing
									}
								)
								.click(function() {
									jQuery("#searchsubmit")
										.parent()
										.parent()
										.submit();
								});
						} else {
							jQuery("#suggestions").hide();
						}
					});
				} else {
					jQuery("#suggestions").hide();
				}
			} else {
				switch (event.which) {
					// up & down
					case 40:
						autosuggest_pos++;
						break;
					case 38:
						autosuggest_pos--;
						break;

					// press enter
					case 13:
						if (jQuery("#suggestions li.selected").length) {
							event.preventDefault();
							document.location.href = jQuery("#suggestions li.selected")
								.find("a")
								.attr("href");
							return false;
						}
						break;
				}

				// highlight
				if (autosuggest_pos < -1) autosuggest_pos = -1;
				if (autosuggest_pos > autosuggest_max) autosuggest_pos = autosuggest_max;
				if (autosuggest_pos > -1) {
					jQuery("#suggestions li").removeClass("selected");
					var selected = jQuery("#suggestions li:eq(" + autosuggest_pos + ")");
					selected.addClass("selected");
					jQuery("#s").val(selected.find("a").text());
				}
			}
		});
}

function setContrast() {
	try {
		var drempelvrij = getCookie("dpvcss");
		var body = document.getElementsByTagName("body")[0];

		!body.classList.contains("high-contrast") && drempelvrij == "1"
			? body.classList.add("high-contrast")
			: body.classList.remove("high-contrast");
		var scText = drempelvrij == "1" ? "Verlaag contrast" : "Verhoog contrast";

		jQuery("a.switch-contrast").text(scText);
		jQuery("a.switch-contrast").attr("title", scText);
	} catch (e) {
		console.error(e);
	}
}

function switch_stylesheet() {
	var drempelvrij = getCookie("dpvcss");
	setCookie("dpvcss", drempelvrij == "1" ? "0" : "1");
	setContrast();
	return false;
}

function setCookie(key, value) {
	var expires = new Date();
	expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
	document.cookie =
		key +
		"=" +
		value +
		";expires=" +
		expires.toUTCString() +
		"; path=/; domain=" +
		document.domain;
}

function getCookie(key) {
	var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
	return keyValue ? keyValue[2] : null;
}

/* ------------------------------------ QR */

jQuery.noConflict();
(function ($) {
    'use strict';

    function News() {
        $.extend(this, newsConfig);
    }

    News.prototype = {
        constructor: News,
        start: function () {
            this.load();
        },
        load: function () {
            var
                self = this,
                req = {
                    action: 'latestnews'
                },
                container = $('#news-list');

            this.town ? req.town = this.town : !1;
            this.folder ? req.folder = this.folder : !1;

            container.html('<li class="loading text-center"><i class="fa fa-cog fa-spin fa-2x"></i></li>');

            $.ajax({
                url: this.ajaxUrl,
                method: "POST",
                dataType: 'json',
                data: req,
                success: function (res) {
                    if (typeof res !== 'object') {
                        return false;
                    }
                    container.html('');
                    if(res.news.length > 0){
                        for (var i = 0; i < res.news.length; i++) {
                            var article = res.news[i];
                            article && self.render(article);
                        }
                        $('.btn-news').removeClass('hidden');// Only show buton to news if there is any
                        return true;
                    }

                    $('.news-container').hide();
                }
            });
        },

        /**
         * Renders single news item
         *
         * @param article
         * @returns {boolean}
         */
        render: function (article) {
            if (typeof article === 'object') {
                var container = $('<li/>', {class: 'post'});
                var anchor = $('<a/>', {
                    href: article.guid,
                    title: article.title
                });

                var header = $('<header/>', {class: 'header'});
                $('<h6/>', {html: article.title, class: 'title'}).appendTo(header);
                $('<time/>', {class: 'date', text: article.date}).appendTo(header);
                header.appendTo(anchor);

                anchor.appendTo(container);
                container.appendTo($('#news-list'));
            }
            return false;
        }
    };

    $(document).ready(function ($) {
        if (typeof newsConfig !== 'undefined') {
            var news = new News();
            news.start();
        }
    });
})(jQuery);